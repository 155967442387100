<template>
  <div class="content__body">
    <preloader v-if="!is_loaded" />
    <template v-else>
      <div class="title">Мероприятия в твоем регионе</div>
      <app-cells position="start">
        <v-select
          @search="onFilterRegionSearch"
          noDrop
          label="id"
          placeholder="Поиск по названию"
          class="select select--minw"
        />
      </app-cells>
      <template v-if="events_region && events_region.length">
        <div class="events">
          <router-link
            :to="{ name: 'event-user-detail', params: { id: event.id } }"
            v-for="event in events_region"
            :key="event.id"
            class="events__item"
          >
            <div class="events__body">
              <div class="events__title">{{ event.name }}</div>
              <div class="events__info">
                <div v-if="event.date_start" class="events__point">
                  <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
                  <span>{{ event.date_start }} - {{ event.date_end }}</span>
                </div>
                <div class="events__point">
                  <img v-if="event.participation_form_name === 'Очное'" src="@/assets/img/events/offline-icon.svg" alt="Participation icon">
                  <img v-if="event.participation_form_name === 'Заочное'" src="@/assets/img/events/online-icon.svg" alt="Participation icon">
                  <img v-if="event.participation_form_name === 'Очно-заочное'" src="@/assets/img/events/on-off-icon.svg" alt="Participation icon">
                  <span>{{ event.participation_form_name }}</span>
                </div>
                <div v-if="event.region_text" class="events__point">
                  <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
                  <span>{{ event.region_text }}</span>
                </div>
              </div>
            </div>
            <div class="events__pic">
              <img v-if="!event.crop_url" src="@/assets/img/events/event-slug.svg" alt="Event slug">
              <img v-else :src="event.crop_url" alt="Event image">
            </div>
          </router-link>
        </div>
      </template>
      <template v-if="events_region && !events_region.length">
        <div class="slug-with-bg">
          <img src="@/assets/img/warning-blue.svg" alt="">
          В вашем регионе нет текущих мероприятий
        </div>
      </template>
      <template v-if="!events_region">
        <div class="slug-with-bg">
          <img src="@/assets/img/warning-blue.svg" alt="">
          Укажите адрес проживания в профиле, чтобы отслеживать мероприятия в своем регионе
        </div>
      </template>

      <app-cells position="start">
        <div class="title">Все мероприятия</div>
      </app-cells>
      <app-cells position="start">
        <v-select
          @search="onFilterSearch"
          noDrop
          label="id"
          placeholder="Поиск по названию"
          class="select select--minw"
        />
      </app-cells>
      <template v-if="events && events.length">
        <div class="events">
          <router-link
            :to="{ name: 'event-user-detail', params: { id: event.id } }"
            v-for="event in events"
            :key="event.id"
            class="events__item"
          >
            <div class="events__body">
              <div class="events__title">{{ event.name }}</div>
              <div class="events__info">
                <div v-if="event.date_start" class="events__point">
                  <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
                  <span>{{ event.date_start }} - {{ event.date_end }}</span>
                </div>
                <div class="events__point">
                  <img v-if="event.participation_form_name === 'Очное'" src="@/assets/img/events/offline-icon.svg" alt="Participation icon">
                  <img v-if="event.participation_form_name === 'Заочное'" src="@/assets/img/events/online-icon.svg" alt="Participation icon">
                  <img v-if="event.participation_form_name === 'Очно-заочное'" src="@/assets/img/events/on-off-icon.svg" alt="Participation icon">
                  <span>{{ event.participation_form_name }}</span>
                </div>
                <div v-if="event.region_text" class="events__point">
                  <img src="@/assets/img/team/calendar.svg" alt="Calendar icon">
                  <span>{{ event.region_text }}</span>
                </div>
              </div>
            </div>
            <div class="events__pic">
              <img v-if="!event.crop_url" src="@/assets/img/events/event-slug.svg" alt="Event slug">
              <img v-else :src="event.crop_url" alt="Event image">
            </div>
          </router-link>
        </div>
      </template>
      <template v-if="events && !events.length">
        <div class="slug-with-bg">
          <img src="@/assets/img/warning-blue.svg" alt="">
          Нет текущих мероприятий
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import Preloader from '@/components/layout/Preloader'

export default {
  name: 'EventsUserList',
  components: { Preloader },
  data() {
    return {
      is_loaded: false,
      events_region: null,
      events: null,
    }
  },
  mounted() {
    Promise.all([
      this.$store.dispatch('profile/personal/GET_DATA'),
      this.$store.dispatch('events/GET_LIST_USER', { search: '', only_region: false })
    ])
      .then(res => {
        if (res[0].data.residence_address_text) this.fetchRegionItems()
        else this.is_loaded = true
        this.events = res[1].data.results
      })
  },
  methods: {
    fetchItems(search = '', only_region = false) {
      return this.$store.dispatch('events/GET_LIST_USER', { search, only_region })
        .then(response => {
          this.events = response.data.results
        })
    },
    fetchRegionItems(search = '', only_region = true) {
      this.$store.dispatch('events/GET_LIST_USER', { search, only_region })
        .finally(() => { this.is_loaded = true })
        .then(response => { this.events_region = response.data.results })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(search)
      loading(false)
    }, 350),
    onFilterRegionSearch(search, loading) {
      loading(true)
      this.searchRegionFilter(loading, search, this)
    },
    searchRegionFilter: debounce((loading, search, vm) => {
      vm.fetchRegionItems(search)
      loading(false)
    }, 350),
  }
}
</script>
